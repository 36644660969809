import * as React from "react";
import { HeartIcon } from "@common";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";

type LikeButtonOrientation = "vertical-mode" | "horizontal-mode";
type LikeButtonSize = "small" | "medium" | "big" | "large";

const TIMER_DEBOUNCING_PER_REQUEST = 100;
let timerAddLikeID: NodeJS.Timeout;
let timerRemoveLikeID: NodeJS.Timeout;

const LikeButton = (props: {
	orientation: LikeButtonOrientation;
	size: LikeButtonSize;
	fetchForAddingLike?: () => Promise<any>;
	fetchForRemovingLike?: () => Promise<any>;
	initialNumberOfLikes?: number;
	loading?: boolean;
	initialUserLike?: boolean;
}) => {
	const {
		orientation,
		size,
		loading,
		initialNumberOfLikes = 0,
		fetchForAddingLike,
		fetchForRemovingLike,
		initialUserLike,
	} = props;
	const [state, setState] = React.useState({
		numberOfLikes: initialNumberOfLikes,
		userLike: initialUserLike,
	});
	const modifyAmountOfLikesAndStatus = (numbersOfLikeToSum: number, statusUserLike: boolean) =>
		setState((prevState) => ({
			...prevState,
			numberOfLikes: prevState.numberOfLikes + numbersOfLikeToSum,
			userLike: statusUserLike,
		}));
	const { numberOfLikes, userLike } = state;

	const increaseOneLike = () => modifyAmountOfLikesAndStatus(1, true);
	const decreaseOneLike = () => modifyAmountOfLikesAndStatus(-1, false);

	const addLike = () => {
		if (fetchForAddingLike) {
			clearTimeout(timerAddLikeID);

			timerAddLikeID = setTimeout(() => {
				increaseOneLike();
				fetchForAddingLike().catch(() => decreaseOneLike());
			}, TIMER_DEBOUNCING_PER_REQUEST);
		}
	};
	const removeLike = () => {
		if (fetchForRemovingLike) {
			clearTimeout(timerRemoveLikeID);

			timerRemoveLikeID = setTimeout(() => {
				decreaseOneLike();
				fetchForRemovingLike().catch(() => increaseOneLike());
			}, TIMER_DEBOUNCING_PER_REQUEST);
		}
	};
	const handleOnCheckHeart = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;

		if (checked) {
			addLike();
		} else {
			removeLike();
		}
	};

	function isLikeDisabled() {
		return !fetchForAddingLike && !fetchForRemovingLike;
	}

	React.useEffect(() => {
		setState((prevState) => ({
			...prevState,
			numberOfLikes: initialNumberOfLikes,
			userLike: initialUserLike,
		}));
	}, [initialNumberOfLikes, initialUserLike]);

	return (
		<div className={`LikeButton ${orientation} ${size}`}>
			<div className={"LikeButton--detail" + (isLikeDisabled() ? " disabled" : "")}>
				{loading ? (
					<Loader
						className="block"
						size={"small"}
						active
						inline={"centered"}
					/>
				) : (
					<>
						<input
							type="checkbox"
							id="checkbox"
							checked={userLike}
							disabled={isLikeDisabled()}
							onChange={handleOnCheckHeart}
						/>
						<label htmlFor="checkbox">
							<HeartIcon />
						</label>
						<span> {numberOfLikes} </span>
					</>
				)}
			</div>
		</div>
	);
};

export default LikeButton;
