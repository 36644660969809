import * as React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import { WhiteWrappersChildren } from "./typing";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";

function WhiteWrapperHeaderTitle(props: { title: string }) {
	const { title } = props;

	return <label className="white-wrapper-title font-uppercase font-bold">{title}</label>;
}

function WhiteWrapperHeaderButtonForTriggeringModal(props: { title: string; children: WhiteWrappersChildren }) {
	const { children } = props;

	return (
		<Grid.Column
			className="white-wrapper-action padding-0_5-all"
			textAlign={"right"}
			computer={"6"}
			tablet={props.title ? "6" : "16"}
		>
			{children.modal}
		</Grid.Column>
	);
}

export default function WhiteWrapperHeader(props: {
	hasModalColumn: boolean;
	title: string;
	children: WhiteWrappersChildren;
	loading: boolean;
}) {
	const { children, title, hasModalColumn, loading } = props;
	const getHeaderColumnSize: SemanticWIDTHS = hasModalColumn ? "10" : "16";
	const hasButtonForTriggeringModal = !loading && hasModalColumn;

	return (
		<>
			<Grid.Column
				verticalAlign="middle"
				computer={getHeaderColumnSize}
			>
				{title && <WhiteWrapperHeaderTitle title={title} />}
				{children.header}
			</Grid.Column>
			{hasButtonForTriggeringModal && (
				<WhiteWrapperHeaderButtonForTriggeringModal title={title}>
					{children}
				</WhiteWrapperHeaderButtonForTriggeringModal>
			)}
		</>
	);
}
