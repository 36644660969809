import { INTERACTION_SERVICE_URL } from "api/config";

// ELEARNINGS

const URL_GET_LIKES_FROM_ELEARNING = (elearningId: string) =>
	`${INTERACTION_SERVICE_URL}/like/elearning/${elearningId}`;

const URL_GET_IF_USER_HAS_LIKED_ELEARNING = (userId: string, elearningId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/elearning/${elearningId}`;

const URL_POST_LIKE_TO_ELEARNING = (userId: string, elearningId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/elearning/${elearningId}`;

const URL_DELETE_LIKE_FROM_ELEARNING = (userId: string, elearningId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/elearning/${elearningId}`;

// COMMENTS

const URL_GET_LIKES_FROM_COMMENT = (commentId: string) => `${INTERACTION_SERVICE_URL}/like/comment/${commentId}`;

const URL_GET_IF_USER_HAS_LIKED_COMMENT = (userId: string, commentId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/comment/${commentId}`;

const URL_POST_LIKE_TO_COMMENT = (userId: string, commentId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/comment/${commentId}`;

const URL_DELETE_LIKE_FROM_COMMENT = (userId: string, commentId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/comment/${commentId}`;

// TALENTS

const URL_GET_LIKES_FROM_TALENT = (talentId: string) => `${INTERACTION_SERVICE_URL}/like/talent/${talentId}`;

const URL_GET_IF_USER_HAS_LIKED_TALENT = (userId: string, talentId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/talent/${talentId}`;

const URL_POST_LIKE_TO_TALENT = (userId: string, talentId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/talent/${talentId}`;

const URL_DELETE_LIKE_FROM_TALENT = (userId: string, talentId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/talent/${talentId}`;

// MARKETPLACE

const URL_GET_LIKES_FROM_MARKETPLACE = (marketplaceId: string) =>
	`${INTERACTION_SERVICE_URL}/like/marketplace/${marketplaceId}`;

const URL_GET_IF_USER_HAS_LIKED_MARKETPLACE = (userId: string, marketplaceId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/marketplace/${marketplaceId}`;

const URL_POST_LIKE_TO_MARKETPLACE = (userId: string, marketplaceId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/marketplace/${marketplaceId}`;

const URL_DELETE_LIKE_FROM_MARKETPLACE = (userId: string, marketplaceId: string) =>
	`${INTERACTION_SERVICE_URL}/like/profile/${userId}/marketplace/${marketplaceId}`;

export {
	URL_GET_LIKES_FROM_ELEARNING,
	URL_GET_IF_USER_HAS_LIKED_ELEARNING,
	URL_POST_LIKE_TO_ELEARNING,
	URL_DELETE_LIKE_FROM_ELEARNING,
	URL_GET_LIKES_FROM_COMMENT,
	URL_GET_IF_USER_HAS_LIKED_COMMENT,
	URL_POST_LIKE_TO_COMMENT,
	URL_DELETE_LIKE_FROM_COMMENT,
	URL_GET_LIKES_FROM_TALENT,
	URL_GET_IF_USER_HAS_LIKED_TALENT,
	URL_POST_LIKE_TO_TALENT,
	URL_DELETE_LIKE_FROM_TALENT,
	URL_GET_LIKES_FROM_MARKETPLACE,
	URL_GET_IF_USER_HAS_LIKED_MARKETPLACE,
	URL_POST_LIKE_TO_MARKETPLACE,
	URL_DELETE_LIKE_FROM_MARKETPLACE,
};
