import {
	defaultError,
	getDeleteHeaderWithCredentials,
	getGetHeader,
	getGetHeaderWithCredentials,
	getPostHeaderWithCredentials,
} from "api/config";
import {
	URL_DELETE_LIKE_FROM_COMMENT,
	URL_DELETE_LIKE_FROM_ELEARNING,
	URL_DELETE_LIKE_FROM_MARKETPLACE,
	URL_DELETE_LIKE_FROM_TALENT,
	URL_GET_IF_USER_HAS_LIKED_ELEARNING,
	URL_GET_IF_USER_HAS_LIKED_MARKETPLACE,
	URL_GET_IF_USER_HAS_LIKED_TALENT,
	URL_GET_LIKES_FROM_ELEARNING,
	URL_GET_LIKES_FROM_MARKETPLACE,
	URL_GET_LIKES_FROM_TALENT,
	URL_POST_LIKE_TO_COMMENT,
	URL_POST_LIKE_TO_ELEARNING,
	URL_POST_LIKE_TO_MARKETPLACE,
	URL_POST_LIKE_TO_TALENT,
} from "api/InteractionAPI/LikeAPI/urls";

export function getLikesFromElearningIdAPI(elearningId: string) {
	return function () {
		return fetch(URL_GET_LIKES_FROM_ELEARNING(elearningId), getGetHeader())
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getIfTheUserHasLikedTheElearningAPI(userId: string, elearningId: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_IF_USER_HAS_LIKED_ELEARNING(userId, elearningId), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postLikeToElearningAPI(userId: string, elearningId: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_LIKE_TO_ELEARNING(userId, elearningId), getPostHeaderWithCredentials({}, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function removeLikeFromElearningAPI(userId: string, elearningId: string, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_LIKE_FROM_ELEARNING(userId, elearningId), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postLikeToCommentAPI(userId: string, commentId: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_LIKE_TO_COMMENT(userId, commentId), getPostHeaderWithCredentials({}, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function removeLikeFromCommentAPI(userId: string, commentId: string, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_LIKE_FROM_COMMENT(userId, commentId), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getLikesFromTalentIdAPI(talentId: string) {
	return function () {
		return fetch(URL_GET_LIKES_FROM_TALENT(talentId), getGetHeader())
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getIfTheUserHasLikedTheTalentAPI(userId: string, talentId: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_IF_USER_HAS_LIKED_TALENT(userId, talentId), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postLikeToTalentAPI(userId: string, talentId: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_LIKE_TO_TALENT(userId, talentId), getPostHeaderWithCredentials({}, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function removeLikeFromTalentAPI(userId: string, talentId: string, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_LIKE_FROM_TALENT(userId, talentId), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getLikesFromMarketplaceIdAPI(marketplaceId: string) {
	return function () {
		return fetch(URL_GET_LIKES_FROM_MARKETPLACE(marketplaceId), getGetHeader())
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getIfTheUserHasLikedTheMarketplaceAPI(userId: string, marketplaceId: string, accessToken: string) {
	return function () {
		return fetch(URL_GET_IF_USER_HAS_LIKED_MARKETPLACE(userId, marketplaceId), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postLikeToMarketplaceAPI(userId: string, marketplaceId: string, accessToken: string) {
	return function () {
		return fetch(URL_POST_LIKE_TO_MARKETPLACE(userId, marketplaceId), getPostHeaderWithCredentials({}, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function removeLikeFromMarketplaceAPI(userId: string, marketplaceId: string, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_LIKE_FROM_MARKETPLACE(userId, marketplaceId), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
