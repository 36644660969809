import classNames from "classnames";
import * as React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import WhiteWrapperHeader from "elements/WhiteWrapper/WhiteWrapperHeader";

import { WhiteWrapperProps } from "./typing";

export class WhiteWrapper extends React.Component<WhiteWrapperProps> {
	render() {
		const { title = "", loading = false, className = "", wrapperMainClassName = "", children } = this.props;
		const hasModalColumn = Boolean(children && children.modal);
		const mustDisplayHeader = Boolean(title || children.modal || children.header);

		return (
			<Grid
				className={classNames(
					"WhiteWrapper",
					"margin-2-bottom",
					this.getShadowClass(),
					className,
					this.getModalClass(),
				)}
				columns={"16"}
			>
				<Grid.Row className="padding-0_5-x padding-0-bottom">
					{mustDisplayHeader && (
						<WhiteWrapperHeader
							hasModalColumn={hasModalColumn}
							title={title}
							loading={loading}
						>
							{children}
						</WhiteWrapperHeader>
					)}
				</Grid.Row>
				{loading ? (
					<Loader
						className="margin-2-y"
						active
						inline="centered"
					/>
				) : (
					<>
						<Grid.Row className={classNames(this.getPaddingForMainWrapper(), wrapperMainClassName)}>
							{children.main}
						</Grid.Row>
						<Grid.Row className="padding-0-all">{children.footer}</Grid.Row>
					</>
				)}
			</Grid>
		);
	}

	private getPaddingForMainWrapper() {
		const { padded } = this.props;

		return padded ? "padding-2-bottom padding-1-x" : "";
	}

	private getModalClass() {
		const { children } = this.props;

		return children.modal ? "" : "padding-0_5-top";
	}

	private getShadowClass() {
		const { shadowOnHover } = this.props;

		return shadowOnHover ? "shadowy" : "";
	}
}
